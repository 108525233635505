import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {ITreeSelectFormElement} from '../form-data.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {TreeComponent, TreeModel, TreeModule, TreeNode} from '@ali-hm/angular-tree-component';

import {TreeOptions} from '@ali-hm/angular-tree-component/lib/models/tree-options.model';
import {SelectElementComponent} from '../select-element/select-element.component';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {clamp} from 'frontier/nucleus';
import {CdkConnectedOverlay, CdkOverlayOrigin, FlexibleConnectedPositionStrategy} from '@angular/cdk/overlay';
import {ICustomTreeNode, INodeData} from '../../../../tree-control/node-data.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatButton} from '@angular/material/button';
import {ClearEntityComponent} from 'frontier/browserkit/src/lib/components/clear-entity/clear-entity.component';
import {CreateNewEntityComponent} from 'frontier/browserkit/src/lib/components/create-new-entity/create-new-entity.component';
import {SearchInputComponent} from '../../search-input/search-input.component';
import {CdkTrapFocus} from '@angular/cdk/a11y';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TreeSelectFormDirective} from './tree-select-form.directive';
import {MatInput} from '@angular/material/input';
import {MatIcon} from '@angular/material/icon';
import {NgClass, NgIf} from '@angular/common';
import {MatFormField, MatLabel, MatPrefix, MatSuffix} from '@angular/material/form-field';

@Component({
  selector: 'kpi4me-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    MatFormField,
    NgIf,
    MatIcon,
    MatPrefix,
    MatLabel,
    MatInput,
    TreeSelectFormDirective,
    FormsModule,
    CdkOverlayOrigin,
    ReactiveFormsModule,
    MatSuffix,
    CdkConnectedOverlay,
    CdkTrapFocus,
    SearchInputComponent,
    TreeModule,
    MatCheckbox,
    NgClass,
    MatTooltip,
    CreateNewEntityComponent,
    ClearEntityComponent,
    MatButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
  ],
})
export class TreeSelectComponent extends SelectElementComponent implements AfterViewInit, OnDestroy {
  private _elementRef = inject(ElementRef);
  private _snackBar = inject(MatSnackBar);
  @ViewChild('tree', {static: false}) tree: TreeComponent;
  @ViewChild('inputTrigger', {static: true}) inputTrigger: ElementRef;
  @ViewChild('panelRef', {static: false}) panelRef: ElementRef;
  @ViewChild(CdkConnectedOverlay, {static: false}) cdkConnectedOverlay: CdkConnectedOverlay;

  config: any;
  @Input() nodes: any;
  override emptyOptionAdded = true;

  @Input() columnName: string;
  treeOptions: Partial<TreeOptions> = {
    get idField(): string {
      return 'signature';
    },
  };

  cellPosition: DOMRect;

  @Output() containerSizeChange = new EventEmitter();
  isOpen = false;
  createRootEnabled: boolean = false;
  private closed: boolean = false;

  @Input()
  override set data(form: ITreeSelectFormElement) {
    if (form.emptyOptionAdded) {
      this.nodes = [{name: ' '}, ...form.nodes];
    } else {
      this.nodes = form.nodes;
    }
    this.createNewEnabled = form.createNewEnabled;
    this.editEnabled = form.editElementEnabled;
    this.deleteEnabled = form.deleteElementEnabled;
    this.createRootEnabled = form.createRootEnabled;
    this.clearEnabled = form.clearEnabled;
    this.getFormControl().setValue(form.value);

    super.data = form;
    this._selection = new SelectionModel<any>(form.multiple, form.value);

  }

  private _selection: SelectionModel<any>;
  override arrowPosition: 'left' | 'right';
  menuPos: { x: number, y: number } = {
    x: 0,
    y: 0
  };
  positionStrategy: FlexibleConnectedPositionStrategy;

  @Input() set selection(s: SelectionModel<any>) {
    if (s != null) {
      this._selection = new SelectionModel<any>(s.isMultipleSelection());
      s.selected.forEach((nodeData) => {
        if (nodeData && nodeData.signature) {
          const node: ICustomTreeNode = this.tree.treeModel.getNodeById(
            nodeData.signature
          );
          if (node) {
            this.selectNode(node, true);
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    this.cellPosition = this._elementRef.nativeElement.getBoundingClientRect();
  }

  get selection():
    SelectionModel<INodeData> {
    return this._selection;
  }

  ngOnDestroy() {
    this.isOpen = false;
  }

  /**
   * Selects a node. The checked attribute of the node is set to the selection state and also the
   * selection model is updated.
   * @param node
   * @param isSet
   * @param recursive
   * @private
   */
  private selectNode(
    node: ICustomTreeNode,
    isSet: boolean,
    recursive: boolean = false,
  ) {
    if (node.data.is_selectable === false) {
      this._snackBar.open(
        'Dieser Knoten kann nicht ausgewählt werden, da er vom falschen Typ ist.',
        'ok',
        {duration: 5000, verticalPosition: 'top'},
      );
    } else {
      // Try to set the isLeaf property correctly if it is missing for any reason
      if (node.data.isleaf === undefined) {
        node.data.isleaf = !(node.children && node.children.length > 0);
      }

      node.checked = isSet;
      if (isSet && node.data.isleaf) {
        this.selection.select(node.data);
      } else {
        this.selection.deselect(node.data);
      }
      // leaves
      if (node.data.isleaf) {
        if (recursive === false) {
          // check if parent is full selected or partially
          this.selectParentNodePartially(node.parent);
        }
      }
      // parents
      else {
        // select all nodes
        if (node.children) {
          node.children.forEach((childNode: ICustomTreeNode) => {
            this.selectNode(childNode, isSet, true);
          });
          this.selectParentNodePartially(node);
        } else {
          this.selection.select(node.data);
        }
      }
      console.log(node.data, node.checked);
    }
  }

  /**
   * For a parent node, checks if a child node is selected or every child node is selected.
   * @private
   * @param parent
   */
  private selectParentNodePartially(parent: ICustomTreeNode) {
    const allChildsSelected = parent.children.reduce(
      (previousValue: ICustomTreeNode, currentValue: ICustomTreeNode) => {
        return previousValue && currentValue.checked;
      },
      true
    );
    if (allChildsSelected) {
      parent.checked = true;
      parent.partiallyChecked = false;
    } else {
      parent.checked = false;
      this.selection.deselect(parent.data);
      if (
        parent.children.findIndex(
          (childNode: ICustomTreeNode) =>
            childNode.checked || childNode.partiallyChecked
        ) != -1
      ) {
        parent.partiallyChecked = true;
      } else {
        parent.partiallyChecked = false;
      }
    }
    if (parent.parent != undefined) {
      this.selectParentNodePartially(parent.parent);
    }
  }

  onNodeSelectionChange(evt: MatCheckboxChange, node: ICustomTreeNode
  ) {
    this.cdr.detach();
    node.checked = node.checked == true ? true : false;
    if (!node.checked) {
      // check the node as selected
      if (this.selection.isMultipleSelection()) {
        this.selectNode(node, true);
      } else {
        // deselect all nodes from the tree model
        this.tree.treeModel.doForAll((n: ICustomTreeNode) => {
          if (n.data.signature != node.data.signature) {
            n.checked = false;
          }
        });
        this.selection.clear();
        this.selectNode(node, true);
      }
    } else {
      // deselect the node
      this.selectNode(node, false);
    }
    this.cdr.reattach();
    this.cdr.detectChanges();
    // this.selectionChange.emit(this.selection);
    this.getFormControl().setValue(this.selection.selected)
  }

  onGetNodeTooltip(node: any, myTooltip: MatTooltip
  ) {

  }

  getNodeTooltip(node: any
  ) {
    return node.data.name;
  }

  onNewNode(parent: any, index: any, param3: any, param4: boolean
  ) {

  }

  showCheckbox(node: any
  ) {
    return true;
  }

  override onSearchChange($event: any) {
    console.log($event)
    this.tree.treeModel.filterNodes($event);
  }

  onFocusIn() {
    if (this.closed === false) {
      this.isOpen = true;
    }
    this.closed = false;
    this.cdr.detectChanges();
  }

  onTreeInit() {
    let model = new SelectionModel(this.multiple);
    model.select(...this.getFormControl().value);
    this.selection = model;
    this.tree.treeModel.expandAll();
    setTimeout(() => {
      this.onResize()
    }, 0)
  }

  onNodeContextMenu(event: MouseEvent, menuTrigger: MatMenuTrigger) {
    this.menuPos = {
      x: event.clientX,
      y: event.clientY
    }
    menuTrigger.openMenu();
  }

  onConfirm() {
    console.log('confirmed selection: ', this.selection.selected);
    this.confirm.emit(this.selection.selected)
  }

  deleteNode(node: TreeNode) {
    node.parent.data.children.splice(node.parent.data.children.indexOf(node.data), 1)
    this.tree.treeModel.update();
    if (node && node.parent && node.parent.data && node.parent.data.children.length === 0) {
      node.parent.data.hasChildren = false;
    }
  }

  onNodeActivate(evt: { eventName: string, node: TreeNode, treeModel: TreeModel }) {
    console.log(evt);
    if (this.selection.isMultipleSelection() === false) {
      if (evt.node.data.is_selectable === false) {
        this._snackBar.open(
          'Dieser Knoten kann nicht ausgewählt werden, da er vom falschen Typ ist.',
          'ok',
          {duration: 5000, verticalPosition: 'top'},
        );
      } else {
        this.selectNode(evt.node as unknown as ICustomTreeNode, true);
        this.getFormControl().setValue([evt.node.data]);

        this.onConfirm();
      }
    }
  }

  onBackdropClick(event: MouseEvent) {
    this.isOpen = false
    this.cdr.detectChanges();
  }

  getColumnTitle() {
    // pass column name
  }

  toggleDropdown() {
    this.closed = true;
    this.isOpen = false;
    this.cdr.detectChanges();
    console.log(this.isOpen);
  }

  onResize() {
    const margin = 10;
    const {
      top,
      left,
      bottom,
      right,
      width,
      height
    } = this.cdkConnectedOverlay.overlayRef.overlayElement.getBoundingClientRect();
    const {innerHeight, innerWidth} = window;
    let x = clamp(left, 0 + margin, innerWidth - margin);
    x = clamp(left, 0 + margin, innerWidth - width - margin);
    let y = clamp(top, 0 + margin, innerHeight - margin);
    y = clamp(top, 0 + margin, innerHeight - height - margin);

    this.cdkConnectedOverlay.overlayRef.overlayElement.style.left = `${x}px`
    this.cdkConnectedOverlay.overlayRef.overlayElement.style.top = `${y}px`
  }
}
