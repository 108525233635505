<mat-tab-group
  mat-stretch-tabs="false"
  [selectedIndex]="selectedIndex()"
  [animationDuration]="200"
  [preserveContent]="true"
  (animationDone)="animationDone.next(null)"
  (selectedIndexChange)="onSelectedIndexChange($event)"
>
  @for (tab of tabs(); track tab.tabId) {
    <mat-tab [label]="tab.label" style="pointer-events: auto">
      <ng-template mat-tab-label>
        <div class="flex-row align-center gap-sm" (auxclick)="removeTab($index, $event)">
          <span style="text-align: center">{{ tab.label }}</span>
          <button (click)="removeTab($index,$event); $event.stopPropagation();" mat-icon-button>
            <mat-icon [svgIcon]="EsvgFiles.close"></mat-icon>
          </button>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <lib-tab-content [data]="tab"/>
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>
