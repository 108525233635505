import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private authService: AuthenticationService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      // Hier ist der User eingeloggt also gib true zurück
      return true;
    }

    // Hier ist der Benutzer nicht berechtig also sende ihn zurück zur login page
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
