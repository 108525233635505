import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  inject, computed, viewChild, effect,
} from '@angular/core';
import {BaseFormElementComponent} from './base-form-element.class';
import {TextElementComponent} from './text-element/text-element.component';
import {
  EFormControlType,
  IFormElement,
  IFormElementStyle,
  ISelectFormElement,
  ISelectFormOption,
} from './form-data.interface';
import {AbstractControl, FormControl} from '@angular/forms';
import {SelectElementComponent} from './select-element/select-element.component';
import {CheckboxElementComponent} from './checkbox-element/checkbox-element.component';
import {DateElementComponent} from './date-element/date-element.component';
import {SearchElementComponent} from './search-element/search-element.component';
import {RadioElementComponent} from './radio-element/radio-element.component';
import {FileUploadElementComponent} from './file-upload-element/file-upload-element.component';
import {TreeSelectComponent} from './tree-select/tree-select.component';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {NgIf, NgStyle} from '@angular/common';
import {EsvgFiles} from 'frontier';
import {
  DynamicFormStore
} from 'frontier/browserkit/src/lib/components/control/form-control/dynamic-form/dynamic-form.store';

const MTypeToComponentMap: Record<number, any> = {
  [EFormControlType.text]: TextElementComponent,
  [EFormControlType.select]: SelectElementComponent,
  [EFormControlType.checkbox]: CheckboxElementComponent,
  [EFormControlType.date]: DateElementComponent,
  [EFormControlType.search]: SearchElementComponent,
  [EFormControlType.radio]: RadioElementComponent,
  [EFormControlType.file]: FileUploadElementComponent,
  [EFormControlType.treeSelect]: TreeSelectComponent,
}

@Component({
  selector: 'kpi4me-form-element',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    NgStyle,
    MatTooltip,
    NgIf,
    MatIcon,
  ],
})
export class FormElementComponent {
  protected readonly EsvgFiles = EsvgFiles;

  private _dynamicFormStore = inject(DynamicFormStore);

  private _errorIconTooltip = viewChild<MatTooltip>('errorIconTooltip');

  @ViewChild('hostElement', {static: true, read: ViewContainerRef}) hostElement!: ViewContainerRef;

  @Input() formControlElement: AbstractControl;

  private _formData: IFormElement;
  @Input() set formData(formData: IFormElement) {
    if (formData) {
      this._formData = formData;
      const stylings = formData.styling;
      this.setStylings(stylings);

      const viewContainerRef = this.hostElement;
      viewContainerRef.clear();

      this.cdr.detach();
      const component = MTypeToComponentMap[formData.formControlType];

      const componentRef = viewContainerRef.createComponent<BaseFormElementComponent>(component);
      componentRef.instance.formControlElement = this.formControlElement as FormControl;
      // the type of value condition serves as an identifier of an enmum select
      if (formData.formControlType === EFormControlType.select && typeof (formData.value) === "number") {
        // adjust the options of an enum select to match the structure of mat select options
        const selectFormData: ISelectFormElement = {
          ...formData,
          options: formData.options.map((o, i) => {
            return {
              name: o,
              value: i,
            } as ISelectFormOption;
          }),
          createNewEnabled: false,
          editElementEnabled: false,
          deleteElementEnabled: false,
        }
        componentRef.instance.data = selectFormData;
        (componentRef.instance as SelectElementComponent).compareWith = (e1: any, e2: any) => {
          return e1 && e2 && e1 === e2;
        };
      } else if (formData.formControlType === EFormControlType.select) {
        // adjust the structure of a repository select to match the structure of a mat select option
        const selectFormData: ISelectFormElement = {
          ...formData,
          options: formData.options.map((o, i) => {
            return {
              ...o,
              value: o,
            } as ISelectFormOption;
          }),
        }
        componentRef.instance.data = selectFormData;

      } else {
        componentRef.instance.data = formData;
      }
      componentRef.instance.formControlElement.setValue(formData.value, {emitEvent: false});
      componentRef.instance.focusOutEvent.subscribe((evt) => {
        // if(formData.formControlType !== EFormControlType.select && formData.formControlType !== EFormControlType.date) {
        //   this.focusOutEvent.emit(evt);
        // }
        this.focusOutEvent.emit(evt);
      })
      this.cdr.reattach();
    }
  }

  get formData(): IFormElement {
    return this._formData;
  }

  @Output() focusOutEvent = new EventEmitter<FormControl>();

  /**
   * Errors selected from the store
   */
  storeError = this._dynamicFormStore.error;
  elementError = computed(() => {
    const storeError = this.storeError();

    if (storeError?.element === this.formData.key) {
      return storeError.message;
    }
    return null;
  })

  constructor(
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {
    effect(() => {
      // Display the error tooltip when the error changes
      const elementError = this.elementError();
      const errorIconTooltip = this._errorIconTooltip();
      if (elementError && errorIconTooltip) {
        errorIconTooltip.show();
      }
    });
  }

  private setStylings(stylings: IFormElementStyle) {
    if (stylings && stylings.rowStart) {
      this.renderer.setStyle(this.elRef.nativeElement, 'grid-row-start', stylings.rowStart);
    }
    if (stylings && stylings.rowEnd) {
      this.renderer.setStyle(this.elRef.nativeElement, 'grid-row-end', stylings.rowEnd);
    }
    if (stylings && stylings.columnStart) {
      this.renderer.setStyle(this.elRef.nativeElement, 'grid-column-start', stylings.columnStart);
    }
    if (stylings && stylings.columnEnd) {
      this.renderer.setStyle(this.elRef.nativeElement, 'grid-column-end', stylings.columnEnd);
    }
    if (stylings && stylings.maxWidth) {
      this.renderer.setStyle(this.elRef.nativeElement, 'max-width', stylings.maxWidth);
    }
    if (stylings && stylings.cssClass) {
      this.renderer.setAttribute(this.elRef.nativeElement, 'class', stylings.cssClass);
    }
    // if(stylings && stylings.height) {
    //   this.renderer.setStyle(this.elRef.nativeElement, 'height', stylings.height);
    // }

  }

  getFlexDirection() {

  }

  getFlexStyle() {
    if (this.formData.styling == null) {
      return {'flex-direction': 'row'};
    }
    switch (this.formData.styling.iconPosition) {
      case 'bottom':
        return {'flex-direction': 'column-reverse'};
      case 'left':
        return {'flex-direction': 'row'};
      case 'right':
        return {'flex-direction': 'row-reverse'};
      case 'top':
        return {'flex-direction': 'column'};
    }
  }
}
