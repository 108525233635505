import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {ITreeState, TreeComponent, TreeModule, TreeNode} from '@ali-hm/angular-tree-component';

import {ITreeSelect} from './tree-events.interface';
import {TreeApiAdapter} from '../adapters/tree-api-adapter';
import {SelectionModel} from '@angular/cdk/collections';
import {ICustomTreeNode, INodeData} from './node-data.interface';
import {catchError, filter, mergeMap, take, tap,} from 'rxjs/operators';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckbox,
  MatCheckboxChange,
  MatCheckboxDefaultOptions
} from '@angular/material/checkbox';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';
import {PartialTreeOptions} from './partial-tree-options.type';
import {EMPTY, Observable} from 'rxjs';
import {DialogService} from '../../../dialogs/dialog.service';

import {
  IAAddNode,
  IAChangeName,
  IAChangeNodePosition,
  IANewNode,
  IANewRootNode,
  IARemoveRootNode,
} from '../table-control/actions.enum';
import {NodeEditorDialogComponent} from './node-editor-dialog/node-editor-dialog.component';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatTooltip} from '@angular/material/tooltip';
import {NodeStyleMenuComponent} from './node-style-menu/node-style-menu.component';
import {DeleteNodeComponent} from './delete-node/delete-node.component';
import {
  BaseControlComponent,
  EControlActions,
  EsvgFiles,
  HierarchyControlService,
  IHierarchyApiInstance,
  TFilter
} from 'frontier/nucleus';
import {ITreeControlConfiguration} from './tree-control-config.interface';
import {ConfirmationDialogComponent} from '../../../dialogs/basics/confirmation-dialog/confirmation-dialog.component';
import {MatRipple} from '@angular/material/core';
import {MatIcon} from '@angular/material/icon';
import {MatButton} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'kpi4me-tree-control',
  templateUrl: './tree-control.component.html',
  styleUrls: ['./tree-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatSlideToggle,
    FormsModule,
    MatButton,
    TreeModule,
    MatIcon,
    MatCheckbox,
    NgClass,
    MatTooltip,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatRipple,
    NodeStyleMenuComponent,
    DeleteNodeComponent,
  ],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {clickAction: 'check'} as MatCheckboxDefaultOptions,
    },
  ]
})
export class TreeControlComponent extends BaseControlComponent {
  private _hierarchyControlService = inject(HierarchyControlService);

  @ViewChild('tree', {static: false}) tree: TreeComponent;
  @ViewChild('filter', {read: ViewContainerRef, static: false})
  filterInput: ViewContainerRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(NodeStyleMenuComponent) styleMenuRef: NodeStyleMenuComponent;
  @ViewChild(DeleteNodeComponent) deleteNodeMenuRef: DeleteNodeComponent;
  @Input() options: PartialTreeOptions = {
    // useCheckbox: true
    idField: 'signature',
    actionMapping: {
      mouse: {
        dragStart: (tree1, node, evt: DragEvent) => {
          evt.dataTransfer.setDragImage(new Image(), 0, 0);
        },
      },
    },
  };
  @Input() state: ITreeState;
  @Input() apiAdapter: TreeApiAdapter = new TreeApiAdapter();
  @Input() filteringType: TFilter = TFilter.Backend;

  @Input() set filterString(filter: string) {
    if (this.filterInput) {
      this.filterInput.element.nativeElement.value = filter;
    }
    this.onStringFilterChange(filter);
  }

  get filterString(): string {
    return this._filterString;
  }

  @Input() set selection(s: SelectionModel<INodeData>) {
    if (s != null) {
      this._selection = new SelectionModel<INodeData>(s.isMultipleSelection());
      this.gotData
        .pipe(
          filter((d) => d != null),
          take(1)
        )
        .subscribe(() => {
          this.cdr.detectChanges();
          s.selected.forEach((nodeData) => {
            if (nodeData) {
              const node: ICustomTreeNode = this.tree.treeModel.getNodeById(
                nodeData.signature
              );
              if (node) {
                this.selectNode(node, true);
              }
            }
          });
          // this.selectionChange.emit(this.selection);
        });
    }
  }

  get selection(): SelectionModel<INodeData> {
    return this._selection;
  }

  @Input() override apiInstance: IHierarchyApiInstance = {
    filter: {},
    custom: {},
    sorting: {},
  };

  @Input()
  override set config(c: ITreeControlConfiguration) {
    if (c) {
      this.treePaddingTop = 0;
      this._config = c;
      if (c.showDoFlattenToggle) {
        this.treePaddingTop += 50;
      }
      if (c.showFilter) {
        this.treePaddingTop += 50;
      }
      if (c.showNewRoot) {
        this.treePaddingTop += 50;
      }
      if (c.showDoShowLeafs) {
        this.treePaddingTop += 50;
      }
    }
  }

  override get config(): ITreeControlConfiguration {
    return this._config;
  }

  @Output() selectionChange = new EventEmitter<SelectionModel<INodeData>>();
  @Output() executedAction = new EventEmitter<EControlActions>();
  @Output() changedNodePosition = new EventEmitter<EControlActions>();
  @Output() treeStateChanged = new EventEmitter();
  menuPos: { x: number; y: number } = {
    x: 0,
    y: 0,
  };
  @Output() nodeStyleUpdated = new EventEmitter();
  @Output() nodeDeleted = new EventEmitter();

  @Output() nodeActivated = new EventEmitter<ITreeSelect>();
  @Output() treeInitialized = new EventEmitter();

  menuNode: TreeNode;
  color: any;
  // how much the tree has to be set downwards, because of the filter, or other actions on top of the tree.
  treePaddingTop = 0;

  protected readonly EsvgFiles = EsvgFiles;

  private _selection = new SelectionModel<INodeData>(false);
  private _filterString: string;

  private treeIsInitialized: boolean;
  private disableSelection = false;

  constructor(
    private dialogService: DialogService
  ) {
    super();
    this.apiAdapter = new TreeApiAdapter();

    // expand the first root after getting the data
    this.subs.add(
      this.gotData.pipe(filter((v) => v != null)).subscribe(() => {
        this.cdr.detectChanges();
        setTimeout(() => {
          if (this.tree && this.tree.treeModel && this.tree.treeModel.roots) {
            this.tree?.treeModel?.roots[0]?.expand();
          }
        }, 0);
      })
    );

    this.subs.add(this.executedAction.subscribe((action) => {
      this._controlStore.controlDataChanged$.emit({changeType: action, GUID: this.GUID})
    }))
  }

  onNodeActivated(evt: ITreeSelect) {
    this.nodeActivated.emit(evt);
  }

  onNodeSelectionChange(evt: MatCheckboxChange, node: ICustomTreeNode) {
    this.cdr.detach();
    node.checked = node.checked === true;
    if (!node.checked) {
      // check the node as selected
      if (this.selection.isMultipleSelection()) {
        this.selectNode(node, true);
      } else {
        // deselect all nodes from the tree model
        this.tree.treeModel.doForAll((n: ICustomTreeNode) => {
          if (n.data.signature != node.data.signature) {
            n.checked = false;
          }
        });
        this.selection.clear();
        this.selectNode(node, true);
      }
    } else {
      // deselect the node
      this.selectNode(node, false);
    }
    console.log(node.data, node.checked);
    this.cdr.reattach();
    this.cdr.detectChanges();
    this.selectionChange.emit(this.selection);
  }

  onTreeInitialized() {
    this.treeIsInitialized = true;
    this.treeInitialized.emit();
  }

  showCheckbox(node: TreeNode): boolean {
    if (!this.config) {
      return false;
    }
    if (this.config.showCheckbox == null) {
      return false;
    }
    return this.config.showCheckbox(node);
  }

  /**
   * Save the angular 2 tree state as a custom property of the control element.
   * @param state
   */
  onTreeStateChange(state: any) {
    this.apiInstance.custom.state = state;
    super.change().subscribe((res) => {
      console.log(res);
    });
    this.treeStateChanged.emit(state);
  }

  isRootNode(node: TreeNode): boolean {
    return node.parent == null;
  }

  /**
   * Function that filters the data by a string. Reset the selection after fetching the data.
   * The function filters either client side or server side, depending on the filteringType of this component.
   * @param filterString The string of the input
   * @private
   */
  protected override debouncedFilterByString(filterString: string): void {
    this.apiInstance.filter = {
      ...this.apiInstance.filter,
      searchstring: filterString,
    };
    if (this.filteringType === TFilter.Frontend) {
      this.tree.treeModel.filterNodes(filterString, true);
    } else {
      this.changeAndFetch().subscribe(() => {
        // Reset the selection
        this.selection = new SelectionModel<INodeData>(
          this.selection.isMultipleSelection(),
          this.selection.selected
        );
      });
    }
  }

  /**
   * Execute an api tree control action. Refresh the tree after each api action.
   * @param action
   * @param data
   * @private
   */
  executeAction(
    action: EControlActions,
    data:
      | IAAddNode
      | IANewNode
      | IANewRootNode
      | IAChangeNodePosition
      | IAChangeName
      | IARemoveRootNode
  ): Observable<any> {
    return this._controlService.controlPostExecuteAction({
        _parameters: [
          this.apiInstance.instanceid,
          action,
          data
        ]
      }
    ).pipe(
      tap(() => this.executedAction.emit(action)),
      mergeMap(() => this.refresh())
    );
  }

  /**
   * For a parent node, checks if a child node is selected or every child node is selected.
   * @private
   * @param parent The parent node
   */
  private selectParentNodePartially(parent: ICustomTreeNode) {
    if (this.config.recursiveSelectionEnabled == false) {
      return;
    }
    const allChildsSelected = parent.children.reduce(
      (previousValue: ICustomTreeNode, currentValue: ICustomTreeNode) => {
        return previousValue && currentValue.checked;
      },
      true
    );
    if (allChildsSelected) {
      parent.checked = true;
      parent.partiallyChecked = false;
    } else {
      parent.checked = false;
      this.selection.deselect(parent.data);
      if (
        parent.children.findIndex(
          (childNode: ICustomTreeNode) =>
            childNode.checked || childNode.partiallyChecked
        ) != -1
      ) {
        parent.partiallyChecked = true;
      } else {
        parent.partiallyChecked = false;
      }
    }
    if (parent.parent != undefined) {
      this.selectParentNodePartially(parent.parent);
    }
  }

  /**
   * Changes the filter.doflatten attribute of the control instance. Also reapplies the selection because the internal _data
   * value has changed.
   * @param evt
   */
  onDoFlattenChange(evt: MatSlideToggleChange) {
    console.log(evt);
    this.apiInstance.filter.doflatten = evt.checked;
    this.changeAndFetch().subscribe(() => {
      // Reset the selection
      this.reapplySelection();
    });
  }

  /**
   * After resetting the _data attribute, the tree has to reapply the selection.
   */
  override refresh(): Observable<any> {
    return super.refresh().pipe(
      tap(() => {
        // Reset the selection
        this.reapplySelection();
        this.cdr.detectChanges();
      })
    );
  }

  /**
   * Method that recreates the selection of the tree nodes. If the _data of this component changes, the tree does not
   * recognize that there were some nodes selected. This method reapplies the selection that was before loading
   * updated data into the tree.
   * @private
   */
  private reapplySelection() {
    this.selection = new SelectionModel<INodeData>(
      this.selection.isMultipleSelection(),
      this.selection.selected
    );
  }

  /**
   * Opens the dialog that asks for the new nodes name.
   * Either creates a new root node or a new normal node.
   * @param parent
   * @param nextSibling
   */
  onNewNode(
    parent: TreeNode,
    nextSibling: TreeNode | null
  ) {
    if (parent.data.rowid == null) {
      parent.expand();
      // the current node is a root node
      this.dialogService.dialog
        .open(NodeEditorDialogComponent, {
          data: {
            title: 'Wurzelknoten erstellen',
          },
        })
        .afterClosed()
        .pipe(
          mergeMap((v) => {
            if (v) {
              return this._hierarchyControlService.hierarchyControlNewNode({
                InstanceId: this.apiInstance.instanceid,
                parent: null,
                name: v.name,
                next_sibling: null,
              });
            } else {
              return EMPTY;
            }
          })
        ).pipe(
        tap(() => this.executedAction.emit(EControlActions.newNode)),
        mergeMap(() => this.refresh()))
        .subscribe();
    } else {
      // normal node
      this.dialogService.dialog
        .open(NodeEditorDialogComponent, {
          data: {
            title: 'Knoten erstellen',
          },
        })
        .afterClosed()
        .pipe(
          mergeMap((v) => {
            if (v) {
              return this._hierarchyControlService.hierarchyControlNewNode({
                InstanceId: this.apiInstance.instanceid,
                parent: parent.data,
                next_sibling: nextSibling ? nextSibling.data : null,
                name: v.name
              });
              //return this.executeAction(EActions.newNode, data);
            } else {
              return EMPTY;
            }
          }),
          tap(() => {
            this.tree.treeModel.getNodeById(parent.id).expand();
          })
        ).pipe(
        tap(() => this.executedAction.emit(EControlActions.newNode)),
        mergeMap(() => this.refresh()))
        .subscribe();
    }
  }

  /**
   * Adds a new root node to the tree.
   */
  addRootNode() {
    this.dialogService.dialog
      .open(NodeEditorDialogComponent, {
        data: {
          title: 'Wurzelknoten erstellen',
        },
      })
      .afterClosed()
      .pipe(
        mergeMap((v) => {
          if (v) {
            return this._hierarchyControlService.hierarchyControlNewNode({
              InstanceId: this.apiInstance.instanceid,
              name: v.name,
              parent: null,
              next_sibling: null,
            });
          } else {
            return EMPTY;
          }
        })
      ).pipe(
      tap(() => this.executedAction.emit(EControlActions.newNode)),
      mergeMap(() => this.refresh()))
      .subscribe();
  }

  /**
   * Right click on node happened. Set the menu Node reference and store the x,y coords for the menu position.
   * @param evt
   * @param node
   */
  onContextMenu(evt: MouseEvent, node: TreeNode) {
    if (
      this.trigger &&
      // is any contextMenuAction present?
      this.config.contextMenuActions &&
      Object.values(this.config.contextMenuActions).some((v) => v == true)
    ) {
      evt.preventDefault();
      this.menuNode = node;
      this.menuPos = {
        x: evt.clientX,
        y: evt.clientY,
      };
      this.trigger.openMenu();
    }
  }

  /**
   * A Node has moved via drag and drop.
   * @param evt
   */
  onMoveNode(evt: {
    from: { index: number; parent: INodeData };
    node: INodeData;
    to: { index: number; parent: INodeData };
  }) {
    const data: IAChangeNodePosition = {
      node: {
        ...evt.node,
        children: null,
      },
      sortingindex: evt.to.index,
      parent: {
        ...evt.to.parent,
        children: null,
      },
      from: {
        ...evt.from.parent,
        children: null
      }
    };
    this._hierarchyControlService.hierarchyControlChangeNodePosition({
      InstanceId: this.apiInstance.instanceid,
      node: evt.node,
      parent: evt.to.parent,
      next_sibling: evt.to.parent.children[evt.to.index + 1] ? evt.to.parent.children[evt.to.index + 1] : null
    })
      .pipe(
        catchError(() => {
          return this.refresh();
        })
      ).pipe(
      tap(() => this.executedAction.emit(EControlActions.changeNodePosition)),
      mergeMap(() => this.refresh()))
      .subscribe(() => {
        this.changedNodePosition.emit();
      });
  }

  /**
   * Selects a node. The checked attribute of the node is set to the selection state and also the
   * selection model is updated.
   * @param node
   * @param isSet
   * @param recursive
   * @private
   */
  private selectNode(
    node: ICustomTreeNode,
    isSet: boolean,
    recursive: boolean = false
  ) {
    node.checked = isSet;
    if (isSet && node.isLeaf) {
      this.selection.select(node.data);
    } else {
      this.selection.deselect(node.data);
    }
    // leaves
    if (node.isLeaf) {
      if (recursive === false) {
        // check if parent is full selected or partially
        this.selectParentNodePartially(node.parent);
      }
    }
    // parents
    else {
      // select all nodes
      if (node.children) {
        node.children.forEach((childNode: ICustomTreeNode) => {
          this.selectNode(childNode, isSet, true);
        });
        this.selectParentNodePartially(node);
      } else {
        this.selection.select(node.data);
      }
    }
  }

  /**
   * Method to rename a node.
   * Opens the dialog for the user prompt of the name.
   * After the user prompt, the api function is called.
   */
  onMenuRename() {
    // normal node
    this.dialogService.dialog
      .open(NodeEditorDialogComponent, {
        data: {
          title: 'Knoten Umbenennen',
          name: this.menuNode.data.name,
        },
      })
      .afterClosed()
      .pipe(
        mergeMap((v) => {
          if (v) {
            return this._hierarchyControlService.hierarchyControlChangeName({
              InstanceId: this.apiInstance.instanceid, name: v.name, node: this.menuNode.data
            });
          } else {
            return EMPTY;
          }
        })
      ).pipe(
      tap(() => this.executedAction.emit(EControlActions.changeName)),
      mergeMap(() => this.refresh()))
      .subscribe();
  }

  onShowLeafToggle(evt: MatSlideToggleChange) {
    /*
    this.apiInstance.filter = {
      ...this.apiInstance.filter,
      showValueReferences: evt.checked,
    };
    this.changeAndFetch().subscribe(() => {});

     */
  }

  getNodeTooltip(node: any): string {
    if (node.data && node.data.tooltip) {
      return node.data.tooltip as string;
    }
    return '';
  }

  onShowDoShowInvisibleNodesToggle(evt: MatSlideToggleChange) {
    /*
    this.apiInstance.filter = {
      ...this.apiInstance.filter,
      showInvisibleReferences: evt.checked,
    };
    this.changeAndFetch().subscribe(() => {});

     */
  }

  /**
   * Fetches the hierarchy path from the server and stores it as a string in the node data. Triggers the tooltip show method.
   * @param node The node which was hovered with the mouse.
   * @param myTooltip MatTooltip reference used to display the tooltip when the request succeeded.
   */
  onGetNodeTooltip(node: any, myTooltip: MatTooltip) {
    if (this.apiInstance == null || this.apiInstance.instanceid == null) {
      return;
    }
    node.data.mouseInside = true;
    if (node.data.tooltip == null) {
      this._controlService.controlPostGetTooltip({
          _parameters: [
            this.apiInstance.instanceid,
            node.data
          ]
        }
      ).subscribe((t: any) => {
        if (t) {
          node.data.tooltip = t.reverse().join(', ');
          this.cdr.detectChanges();
          if (node.data.mouseInside) {
            myTooltip.show();
          }
        }
      });
    }
  }

  onNodeStyleUpdate() {
    this.nodeStyleUpdated.emit();
  }

  onNodeDeleted() {
    this.nodeDeleted.emit();
  }

  onNodeDeletePress(evt: {
    mode: 'node' | 'children' | 'all';
  }) {
    this.dialogService.dialog.open(
      ConfirmationDialogComponent, {
        data: {
          title: 'Wollen Sie wirklich den Knoten löschen?',
          description: '',
          showCustomCheckBox: true,
          checkBoxText: 'Referenzen auf diesem Knoten automatisch Löschen'
        }
      }
    ).afterClosed().subscribe((result) => {
      console.log(result)
      if (result.result === true) {
        this._hierarchyControlService.hierarchyControlRemoveNode(
          {
            InstanceId: this.apiInstance.instanceid,
            node: this.menuNode.data,
            delete_nodes_and_children_recursively: evt.mode === 'all',
            delete_just_children_recursively: evt.mode === 'children',
            allow_deletion_of_references_to_deleted_nodes: !!result.checkBox
          }).pipe(
          tap(() => this.executedAction.emit(EControlActions.removeNode)),
          mergeMap(() => this.refresh()))
          .subscribe();

      }
    })
  }

  onDeleteSingleNode() {
    if (this.menuNode.data.isManualReference) {
      const data = {
        reference: {
          ...this.menuNode.data,
        },
      };
      this._controlService.controlPostExecuteAction({
          _parameters: [
            this.apiInstance.instanceid,
            'RemoveNode',
            data
          ]
        }
      ).subscribe(() => {
        this.nodeDeleted.emit();
        this.executedAction.emit(EControlActions.removeNode)
      });
    } else {
      this.onNodeDeletePress({mode: 'node'});
    }
  }
}
