import {Component, EventEmitter, inject, input, InputSignal, Output} from '@angular/core';
import {AsyncPipe, JsonPipe, NgIf} from '@angular/common';
import {AuthenticationService, IMenuOption, LoginStore, TSideNavContentItem} from 'frontier/browserkit';
import {MatDrawer, MatDrawerContainer, MatDrawerContent} from '@angular/material/sidenav';
import {Router, RouterOutlet} from '@angular/router';
import {ISideNavContentCategory} from './side-nav-content/side-nav-content-category.interface';
import {SideNavContentComponent} from './side-nav-content/side-nav-content.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {ApiConfigService, StoreService, ThemeService} from 'frontier/nucleus';
import {MatTab, MatTabContent, MatTabGroup} from '@angular/material/tabs';
import {TabsComponent} from './tabs/tabs.component';
import {TabsService} from './tabs/tabs.service';
import {ITab} from 'frontier/browserkit/src/lib/components/home-without-gl/tabs/tab.interface';

@Component({
  selector: 'lib-home-without-gl',
  standalone: true,
  imports: [
    AsyncPipe,
    MatDrawer,
    MatDrawerContainer,
    MatDrawerContent,
    NgIf,
    RouterOutlet,
    SideNavContentComponent,
    JsonPipe,
    ToolbarComponent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    TabsComponent,
  ],
  templateUrl: './home-without-gl.component.html',
  styleUrl: './home-without-gl.component.scss'
})
export class HomeWithoutGlComponent {
  themeService = inject(ThemeService);
  private _tabsService = inject(TabsService);
  private _store = inject(StoreService);
  private _apiConfigService = inject(ApiConfigService);
  private _authService = inject(AuthenticationService);
  private _loginService = inject(LoginStore);
  private _router = inject(Router);
  sidePanelCategories: InputSignal<ISideNavContentCategory[]> = input.required<ISideNavContentCategory[]>();
  multipleInstancesCategoryIds: InputSignal<string[]> = input.required<string[]>();
  productName = input<string>();
  showTenantMenu = input<boolean>(true);
  logoPath = input<string>(null);
  productLogoPath = input<string>(null);
  menuOptions = input<IMenuOption[]>([]);
  versionNumber = input<string>('');
  helpUrl = input<string | null>('https://kpi4me.odoo.com/knowledge/article/52');
  tabs = this._tabsService.tabs;
  selectedTabIndex = this._tabsService.selectedTabIndex;
  @Output() optionTriggered = new EventEmitter<any>()

  logout() {
    this._store.resetLastLogin();
    if (this._store.isAuthenticated()) {
      if (this._apiConfigService.environment.env !== 'prod') {
        setTimeout(() => {
          this._authService.deleteSession();
        }, 500)
      }
      this._loginService.logout()
        .subscribe(() => {
          this._loginService.loggedOut$.emit();
          this._router.navigate(['/login']);
        });
    } else {
      console.log('not authenticated');
      this._loginService.loggedOut$.emit();
      this._router.navigate(['/login']);
    }
  }

  onSideNavItemClick(item: TSideNavContentItem): void {
    if ('link' in item) {
      // open the link
      window.open(item.link, '_blank');
    } else {
      // prevent adding the same tab twice
      if (this.tabs().find((tab: ITab): boolean => tab.tabId === item.tabId
        && !this.multipleInstancesCategoryIds().includes(tab.tabId))) {
        this._tabsService.selectTabById(item.title);
        return;
      }
      this._tabsService.openTab(item.title, item.componentName, item.tabId);
    }
  }
}
