import {Component, ElementRef, EventEmitter, Input, Output, ViewContainerRef,} from '@angular/core';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {EsvgFiles} from 'frontier/nucleus';
import {MatIcon} from '@angular/material/icon';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  standalone: true,
  imports: [
    CdkDrag,
    CdkDragHandle,
    MatDialogTitle,
    MatIcon,
  ],
})
export class DialogHeaderComponent {
  @Input() title: string;
  @Input() dialogRef: MatDialogRef<any>;
  @Output() close = new EventEmitter();

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef
  ) {
  }


  onCloseClick(evt: MouseEvent) {
    this.close.emit(evt);
  }

  protected readonly EsvgFiles = EsvgFiles;
}
