import {Component, inject} from '@angular/core';
import {MatTab, MatTabContent, MatTabGroup, MatTabLabel} from '@angular/material/tabs';
import {TabContentComponent} from './tab/tab-content.component';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {EsvgFiles} from 'frontier/nucleus';
import {TabsService} from '../tabs/tabs.service';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'lib-tabs',
  standalone: true,
  imports: [
    MatTab,
    MatTabContent,
    MatTabGroup,
    TabContentComponent,
    MatTabLabel,
    MatIconButton,
    MatIcon
  ],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
  protected readonly EsvgFiles = EsvgFiles;
  private _tabsService = inject(TabsService);

  selectedIndex = this._tabsService.selectedTabIndex;
  tabs = this._tabsService.tabs;
  animationDone = new Subject();

  removeTab(index: number, evt: MouseEvent) {
    evt.stopPropagation();
    evt.preventDefault();
    this._tabsService.closeTabByIndex(index);
  }

  onSelectedIndexChange(idx: number) {
    // Wait for the animation to finish before selecting the tab
    this.animationDone.pipe(take(1)).subscribe(() => {
      this._tabsService.selectTabByIndex(idx);
    })
  }
}
