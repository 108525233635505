<div class="mainWrapper">
  <lib-toolbar
    (logout)="logout()"
    [productName]="productName()"
    [productLogoPath]="productLogoPath()"
    [logoPath]="logoPath()"
    [versionNumber]="versionNumber()"
    [showTenantMenu]="showTenantMenu()"
    [menuOptions]="menuOptions()"
    [helpUrl]="helpUrl()"
    (optionTriggered)="optionTriggered.emit($event)"
    (themeToggle)="themeService.toggleTheme()"
    (applyTheme)="themeService.applyTheme($event)"
  />

  <mat-drawer-container
    autosize
    id="nav-wrapper"
  >
    <mat-drawer
      #drawer
      [opened]="true"
      mode="side"
    >
      <div
        style="height: 100%; justify-content: space-between; display: flex; flex-direction: column"
      >
        <lib-side-nav-content
          [state]="'opened'"
          [sidePanelCategories]="sidePanelCategories()"
          (itemClicked)="onSideNavItemClick($event)"
        ></lib-side-nav-content>
      </div>
    </mat-drawer>

    <mat-drawer-content
      style="display: flex; flex-direction: column;"
    >
      @if (tabs().length) {
        <lib-tabs/>
      } @else {
        <div class="mat-h2 flex-row centered" style="padding-top: 1rem;">
          Keine Sicht geöffnet. Bitte wählen Sie eine Sicht in der Seitennavigation aus.
        </div>
      }
    </mat-drawer-content>
  </mat-drawer-container>
</div>

