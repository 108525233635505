import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  OnInit,
  Output,
  Renderer2,
  signal,
  ViewEncapsulation
} from '@angular/core';
import {IControlDataChanged, TableControlService} from 'frontier/nucleus';
import {ITableVerticalDataRow} from '../../table-vertical-api-adapter';
import {EMPTY, Observable, of} from 'rxjs';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import {catchError, finalize, tap} from "rxjs/operators";
import {
  ICellChangedEvent
} from '../cell-changed-event.interface';

@Component({
  selector: 'kpi4me-base-cell-editor',
  templateUrl: './base-cell-editor.component.html',
  styleUrls: ['./base-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
})
export class BaseCellEditorComponent implements OnInit {
  protected _tableControlService = inject(TableControlService);
  protected initialValue: any;

  protected _row: ITableVerticalDataRow;

  formControl: FormControl;

  invalidMessageValidator: ValidatorFn;
  @Output() cellChanged = new EventEmitter<ICellChangedEvent>();
  @Output() updateTable = new EventEmitter();
  @Output() selectionDataChanged = new EventEmitter<IControlDataChanged>();
  @Output() updateRepositories = new EventEmitter();
  @Output() changeComplete = new EventEmitter();
  error = signal(null);

  set row(r: ITableVerticalDataRow) {

    this.initialValue = r.value;

    this._row = r;

    this.invalidMessageValidator = (control: AbstractControl) => {
      return r.invalidMessage && (control.value === null || control.value === '') ? {invalidMessage: r.invalidMessage} : null;
    };

    this.formControl = new FormControl(r.value, [this.invalidMessageValidator]);
    this.formControl.markAllAsTouched();

  }

  updateRow(r: ITableVerticalDataRow) {
    this.initialValue = r.value;
    this._row = r;
    this.formControl.removeValidators(this.invalidMessageValidator)
    this.invalidMessageValidator = (control: AbstractControl) => {
      return r.invalidMessage && (control.value === null || control.value === '') ? {invalidMessage: r.invalidMessage} : null;
    };
    this.formControl.addValidators(this.invalidMessageValidator)
    this.formControl.setValue(r.value);
    this.formControl.markAllAsTouched();

  }

  get row(): ITableVerticalDataRow {
    return this._row;
  }

  getValueForApi = () => {
    console.log(this.formControl.value);
    if (this.formControl.valid === false) {
      return this.initialValue;
    }
    return this.formControl.value;
  }

  constructor(
    protected renderer: Renderer2,
    protected element: ElementRef,
    protected cdr: ChangeDetectorRef,
  ) {
  }

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    console.log('enter keydown');

    this.changeValue().subscribe(() => {
      const cellEditorWrapper = this.renderer.parentNode(this.element.nativeElement);
      const td = this.renderer.parentNode(cellEditorWrapper);
      const tr = this.renderer.parentNode(td);
      const nextRow = this.renderer.nextSibling(tr);
      console.log(nextRow);
      try {
        const inputOfNextRow = nextRow.children[1].children[0].children[0].children[0];
        (<HTMLInputElement>inputOfNextRow).focus();
      } catch (e) {
        console.warn('Could not select next input element');
      }
    })
  }

  @HostListener('focusout', ['$event']) onBlur() {
    console.log('focusout');
    this.confirmChange();
  }


  ngOnInit(): void {
    return null;
  }

  changeValue(): Observable<any> {
    if (this.getValueForApi() == this.initialValue) {
      console.log('Value not changed');
      return EMPTY;
    }
    return this._tableControlService.tableControlChangeLine({
      InstanceId: this.row.apiInstance.instanceid,
      RowObject: this.row.apiRow.obj,
      RowIdx: this.row.apiRow.rowidx,
      Attribute: this.row.attribute,
      AttributeIndex: this.row.attributeIndex,
      Value: this.getValueForApi(),
    }).pipe(tap(rowResponse => {
      this.error.set(null);
      this.getValueForApi();
      this.cellChanged.emit({apiRow: rowResponse, verticalRow: this.row});
      this.updateRepositories.emit();
    }), catchError((err) => {
      // this.formControl.patchValue(this.initialValue);
      this.error.set(err.error?.error?.userMsg);
      return of(err)
    }))
  }

  confirmChange(): void {
    this.changeValue().pipe(
      finalize(() => this.changeComplete.emit())
    ).subscribe();
  }
}
