import {ChangeDetectorRef, Component, inject, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {ControlStore, EsvgFiles, UserService} from 'frontier/nucleus';
import {ConfirmationDialogComponent} from "../../../../dialogs/basics/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FeedbackService} from "../../../../services/feedback.service";
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {NgFor, NgIf} from '@angular/common';

export interface ITenant {
  guid: string;
  name: string;
}

export interface IModuleFilter {
  name: string,
  attributes: string[],
}

@Component({
  selector: 'kpi4me-tenant-menu',
  templateUrl: './tenant-menu.component.html',
  styleUrls: ['./tenant-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatMenuTrigger, MatMenu, NgFor, MatMenuItem, NgIf, MatIconButton, MatIcon, MatTooltip]
})
export class TenantMenuComponent {
  private _userService = inject(UserService);
  private _controlStore = inject(ControlStore);
  private _dialog = inject(MatDialog);
  private _feedBackService = inject(FeedbackService);
  private _cdr = inject(ChangeDetectorRef);

  @ViewChild(MatMenu, {static: true}) menuRef: MatMenu;
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  @Input() moduleFilters: IModuleFilter[] = [];

  tenants: ITenant[] = [];
  defaultTenant = '';
  currentTenant = '';
  protected readonly EsvgFiles = EsvgFiles;

  public updateAndOpen(): void {
    this._userService.userGetGetTenantInfos().subscribe((_) => {
      const res = _ as {
        default: string,
        current: string,
        tenants: ITenant[]
      };
      this.defaultTenant = res.default;
      this.currentTenant = res.current;
      this.tenants = res.tenants;
      this._cdr.detectChanges();
    });
  }

  changeTenant(tenant: ITenant) {
    if (tenant.guid !== this.currentTenant) {
      console.log('Updating tenant: ', tenant);
      this._dialog.open(
        ConfirmationDialogComponent,
        {
          data: {
            title: 'Wollen Sie den Mandanten wirklich wechseln?',
            description: 'Die Anwendung wird daraufhin neu geladen. Wir empfehlen andere Tabs vorher zu schließen.',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein',
          }
        }
      ).afterClosed().subscribe((res: any) => {
        if (res) {
          this._controlStore.tenantChanged$.next(res);
          this.moduleFilters.forEach((filter: IModuleFilter) => {
            const loadedFilter: any = JSON.parse(localStorage.getItem(filter.name));
            filter.attributes.forEach((attribute: string) => {
              if (Object.prototype.hasOwnProperty.call(loadedFilter, attribute)) {
                delete loadedFilter[attribute];
              }
            });
            localStorage.setItem(filter.name, JSON.stringify(loadedFilter));
          });

          this._userService.userGetSetTenant(tenant.guid).subscribe((res) => {
            if (res) {
              window.location.reload();
            }
          });
        }
      });
    }
  }

  changeDefaultTenant() {
    this._userService.userGetSetCurrentTenantAsDefault().subscribe((res) => {
      if (res) {
        this._feedBackService.setNotification('Dieser Mandant wird nach erneutem Sitzungsbegin geöffnet.')
      }
    });
  }
}
