import AppControlGuids from '../api/ControlGuids';

/**
 * Map that contains for each key a set of guids that need
 * to be authorized to access the corresponding component.
 * At least one of the guids in the set must be present in the
 * user's roles to access the component.
 */
export const AUTHORIZATION_CONFIG: { [key: string]: (string[]) } = {
  // --- SIDE NAVIGATION -------
  ProcessOverviewComponent: [
    AppControlGuids.TFullCartStatus,
    AppControlGuids.TAllClientList,
    AppControlGuids.TOutboxBook,
    AppControlGuids.TOpenItemList,
    AppControlGuids.TCartList
  ],
  PayerOverviewComponent: [AppControlGuids.TPayerTableView, AppControlGuids.TAlternativePayerView, AppControlGuids.TDataOfficeTableView],
  TariffChangesComponent: [AppControlGuids.TItemPriceHistoryView],
  TemplateEditorComponent: [AppControlGuids.TTemplateConfigurationControl],
  DunningConfigComponent: [AppControlGuids.TDunningConfigView, AppControlGuids.TDunningLevelView],
  DtaServiceConflictTableComponent: [AppControlGuids.TDTAServiceConfigView],
  ConfigurationOverviewComponent: [
    AppControlGuids.TEpostConfiguration,
    AppControlGuids.TCustomerBasedataConfiguration,
    AppControlGuids.TBookingOptionsConfig,
    AppControlGuids.TOpenItemImportConfig
  ],
  // --- SIDE DEV NAVIGATION ---
  OnboardingOverviewComponent: [
    AppControlGuids.TPayerDataOfficeTable,
    AppControlGuids.TPayerTable,
    AppControlGuids.TItemTable,
    AppControlGuids.TItemgroupTable,
    AppControlGuids.TItemPriceHistoryView,
    AppControlGuids.TBusinessAreaMappingTable,
    AppControlGuids.TItemPriceMapping,
    AppControlGuids.TAlternativePayerView,
    AppControlGuids.TPayerTableView,
    AppControlGuids.TBookingOptionsConfig,
    AppControlGuids.TCustomerBasedataConfiguration,
    AppControlGuids.TDTAServiceConfigView,
    AppControlGuids.TGlobalTemplateConfigForm,
    AppControlGuids.TInvoiceTemplateImportTable,
    AppControlGuids.TTemplateConfigurationControl,
    AppControlGuids.TDunningConfigView,
    AppControlGuids.TDunningLevelView,
    AppControlGuids.TOpenItemImportConfig,
    AppControlGuids.TEpostConfiguration
  ],
  ItemTableComponent: [AppControlGuids.TItemTable],
  ItemGroupBaseTableComponent: [AppControlGuids.TItemgroupTable],
  PayerTableComponent: [AppControlGuids.TPayerTable],
  DataOfficeBaseTableComponent: [AppControlGuids.TPayerDataOfficeTable],
  InvoiceTemplateTableComponent: [AppControlGuids.TInvoiceTemplateTable],
  CollectiveinvoiceTableComponent: [AppControlGuids.TCollectiveInvoiceTable],
  CarttypeTableComponent: [AppControlGuids.TCartTypeTable],
  AddressinfoTableComponent: [AppControlGuids.TAddressInfoView],
  BusinessAreasTableComponent: [AppControlGuids.TBusinessAreaMappingTable],
  ItemPriceMappingTableComponent: [AppControlGuids.TItemPriceMapping],
  InvoiceTemplateConfigFormComponent: [AppControlGuids.TInvoiceTemplateConfigForm],
  GlobalTemplateConfigFormComponent: [AppControlGuids.TGlobalTemplateConfigForm],
  BaseInvoiceTemplateConfigTableComponent: [AppControlGuids.TInvoiceTemplateImportTable],
  // ----------------------------

  // --- CART DETAIL TABS ------
  ConspicuityDetailComponent: [AppControlGuids.TNotBillable],
  ClientDetailComponent: [AppControlGuids.TClientList],
  TransportDetailComponent: [AppControlGuids.TTransportList],
  CrewDetailComponent: [AppControlGuids.TOrdinanceList, AppControlGuids.TCrewList],
  CartAdditionalDetailComponent: [AppControlGuids.TCrudCartAdditionaldata],
  InvoiceDetailComponent: [AppControlGuids.TInvoiceList, AppControlGuids.TDunningInfo, AppControlGuids.TCartItemsList],
  TrapoDetailComponent: [AppControlGuids.TTrapoList],
  InternalNoteDetailComponent: [AppControlGuids.TInternalNotes],
  PermanentOrdinanceComponent: [AppControlGuids.TPermanentOrdinanceForClient],
  // ----------------------------

  // --- PAYER OVERVIEW TABS ----
  PayerHiddenTableComponent: [AppControlGuids.TPayerTableView],
  DataofficeTableComponent: [AppControlGuids.TDataOfficeTableView],
  AlternativePayerTableComponent: [AppControlGuids.TAlternativePayerView],
  // ----------------------------

  // --- GENERAL SETTINGS TABS -- (App Configuration Overview)
  CustomerBasedataConfigFormComponent: [AppControlGuids.TCustomerBasedataConfiguration],
  BookingOptionsConfigFormComponent: [AppControlGuids.TBookingOptionsConfig],
  OpenItemImportConfigFormComponent: [AppControlGuids.TOpenItemImportConfig],
  EpostConfigWrapperComponent: [AppControlGuids.TEpostConfiguration],
  // ----------------------------
}
