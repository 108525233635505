import {computed, effect, EventEmitter, inject, Injectable, signal} from '@angular/core';
import {IToolbarLocalStorageState, IToolbarState} from './interfaces';
import {ECalendar} from '../../calendar/calendar.type';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {EGranularity, FromNgbDate, ICalendarChangeEvent, ToNgbDate} from 'frontier/browserkit';
import {getQuarter, IFilter} from 'frontier/nucleus';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {DateTime} from 'luxon';
import {BUILD_HASH_TOKEN} from 'frontier/nucleus/src/lib/configurations/build-hash';

class LocalStorageManager {
  static readonly today = new Date();
  static readonly LOCALSTORAGE_KEY = 'toolbar';
  static getInitialState(buildHash: string): IToolbarState {
    return {
      calendarType: ECalendar.classic,
      classicalCalendar: {
        fromDate: new NgbDate(
          this.today.getFullYear() - 1,
          this.today.getMonth() + 1,
          this.today.getDate(),
        ),
        toDate: new NgbDate(
          this.today.getFullYear(),
          this.today.getMonth() + 1,
          this.today.getDate(),
        ),
        granularity: EGranularity.month,
      },
      easyDatePicker: {
        year: [this.today.getFullYear()],
        month: [this.today.getMonth() + 1],
        quarter: ['Q' + getQuarter()],
      },
      filter: {},
      buildHash,
    }
  }

  static toStorage(state: IToolbarState): void {
    const toolbarSate: IToolbarLocalStorageState = {
      ...state,
      classicalCalendar: {
        fromDate: FromNgbDate(state.classicalCalendar.fromDate).toISO(),
        toDate: FromNgbDate(state.classicalCalendar.toDate).toISO(),
        granularity: state.classicalCalendar.granularity,
      }
    }
    localStorage.setItem(this.LOCALSTORAGE_KEY, JSON.stringify(toolbarSate));
  }

  static fromStorage(buildHash: string): IToolbarState {
    try {
      const localStorageState: IToolbarLocalStorageState = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_KEY));
      if (buildHash !== localStorageState.buildHash) {
        return this.getInitialState(buildHash);
      }
      return {
        ...localStorageState,
        classicalCalendar: {
          fromDate: ToNgbDate(DateTime.fromISO(localStorageState.classicalCalendar.fromDate)),
          toDate: ToNgbDate(DateTime.fromISO(localStorageState.classicalCalendar.toDate)),
          granularity: localStorageState.classicalCalendar.granularity,
        }
      }
    } catch (e) {
      console.warn('Failed to parse toolbar state from local storage, returning initial state');
      return this.getInitialState(buildHash);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarStore {
  buildHash = inject(BUILD_HASH_TOKEN, {optional: false});
  state = signal<IToolbarState>(LocalStorageManager.fromStorage(this.buildHash));
  state$ = toObservable(this.state);

  // actions
  resetFullTextFilter$ = new EventEmitter();

  // selectors
  searchFilter = computed(() => this.state().filter?.searchstring);

  toolbarFilterDto = computed(() => {
    const state = this.state();
    const apiFilter: IFilter = {
      objectlist: [],
      dateranges: undefined,
      granularity: undefined,
    };
    if (state.calendarType === ECalendar.easy) {
      if (
        state.easyDatePicker.month.length !== 0 &&
        state.easyDatePicker.year.length !== 0
      ) {
        apiFilter.dateranges = {
          months: state.easyDatePicker.month,
          years: state.easyDatePicker.year,
        };
        apiFilter.startdate = null;
        apiFilter.enddate = null;
      }
    } else {
      // classical calendar
      // Initial setting of the filter for the table instance.
      const calender = state.classicalCalendar;
      const outEvt: ICalendarChangeEvent = {
        from: FromNgbDate(calender.fromDate).toFormat('yyyy-MM-dd'),
        to: FromNgbDate(calender.toDate).toFormat('yyyy-MM-dd')
      };
      apiFilter.granularity = state.classicalCalendar.granularity;
      apiFilter.startdate = outEvt.from;
      apiFilter.enddate = outEvt.to;
    }

    apiFilter.searchstring = state.filter.searchstring;

    return apiFilter;
  })

  toolbarFilterDto$ = toObservable(this.toolbarFilterDto);

  constructor() {
    effect(() => {
      const state = this.state();
      LocalStorageManager.toStorage(state);
    });

    this.resetFullTextFilter$.pipe(
      takeUntilDestroyed()
    ).subscribe(() => this.resetFullTextFilter())
  }

  private resetFullTextFilter() {
    if (!this.state().filter) return;
    this.state.update(state => {
      return {
        ...state,
        filter: {
          ...state.filter,
          searchstring: ''
        }
      }
    })
  }
}
