import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {EsvgFiles, SvgService} from 'frontier/nucleus';
import {BaseFormElementComponent} from '../base-form-element.class';
import {IFileUploadFormElement} from '../form-data.interface';
import {FeedbackService} from '../../../../../../services/feedback.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconButton} from '@angular/material/button';
import {MatProgressBar} from '@angular/material/progress-bar';
import {MatTooltip} from '@angular/material/tooltip';
import {NgIf, NgStyle} from '@angular/common';
import {MatChipListbox, MatChipOption} from '@angular/material/chips';
import {MatIcon} from '@angular/material/icon';
import {DropUploadDirective} from 'frontier/browserkit/src/lib/components/upload-wrapper/drop-upload.directive';

@Component({
  selector: 'kpi4me-file-upload-element',
  templateUrl: './file-upload-element.component.html',
  styleUrls: ['./file-upload-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    DropUploadDirective,
    MatIcon,
    MatChipListbox,
    MatChipOption,
    NgIf,
    MatTooltip,
    MatProgressBar,
    NgStyle,
    MatIconButton,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class FileUploadElementComponent extends BaseFormElementComponent {
  @ViewChild('fileUpload') fileUploadRef: ElementRef;
  @Input() readAs: 'ArrayBuffer' | 'Text' = 'ArrayBuffer';
  @Input() maxSize: number;
  @Input() accept: string;
  @Input() uploading = false;
  @Input() selectType: "upload" | "serverFiles";

  document: { data?: string | ArrayBuffer; name?: any; fileFormate?: any } = null

  @Input()
  override set data(form: IFileUploadFormElement) {
    this.maxSize = form.maxSize;
    this.accept = form.fileTypes;
    this.readAs = form.readAs;
    this.selectType = form.selectType;

    super.data = form;
  }

  constructor(private svgService: SvgService, private feedbackService: FeedbackService) {
    super();
  }

  get fileName(): string {
    if (this.document && this.document.name) {
      const array = this.document.name.split('\\');
      return array[array.length - 1];
    }
    return null;
  }

  onUploadClick() {
    this.fileUploadRef.nativeElement.click();
  }

  private readFile(fileReaded: any) {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      console.log(reader.result);
      // Ergebnis vom FileReader auslesen
      this.document = {
        name: fileReaded.name,
        data: e.target.result,
        fileFormate: fileReaded.name.split('.')[1],
      };
      // this.onChange(this.document);
      // this.markAsTouched();
    };

    switch (this.readAs) {
      case 'ArrayBuffer':
        reader.readAsArrayBuffer(fileReaded);
        break;
      case 'Text':
        reader.readAsText(fileReaded, 'UTF-8');
        break;
    }
  }

  onFileDrop(evt: FileList) {
    this.fileUploadRef.nativeElement.files = evt;

    const dT = new DataTransfer();
    dT.items.add(evt[0]);
    this.fileUploadRef.nativeElement.files = dT.files;

    this.readFile(evt[0]);
  }


  addAttachment(evt: Event) {
    const fileReaded = (evt.target as any).files[0];
    console.log('add attachment');
    if (this.accept) {
      try {
        const dotSplit = fileReaded.name.split('.');
        const accepted = this.accept.includes(dotSplit[dotSplit.length - 1]);
        if (accepted === false) {
          this.feedbackService.setError(`Der Dateityp ist ungültig. Nur Dateien mit den Endungen ${this.accept} sind gültig.`);
          return;
        }
      } catch (e) {
        this.feedbackService.setError(`Der Dateityp ist ungültig. Nur Dateien mit den Endungen ${this.accept} sind valide.`)
        return;
      }
    }
    this.readFile(fileReaded);
  }

  onDeleteImage() {
    this.document = null;
    this.fileUploadRef.nativeElement.value = '';
    this.uploading = false;
  }


  getFileEndTooltip(): string {
    if (this.accept == null) {
      return '';
    }

    return `Dateien der Typen ${this.accept} sind gültig. Bitte wählen Sie eine Datei von diesem Typen aus.`

  }

  protected readonly EsvgFiles = EsvgFiles;
}
