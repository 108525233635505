import {Component, EventEmitter, inject, input, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatPrefix, MatSuffix} from '@angular/material/form-field';
import {MatRipple} from '@angular/material/core';
import {MatToolbar} from '@angular/material/toolbar';
import {MatTooltip} from '@angular/material/tooltip';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {EsvgFiles, ThemeService, UserService} from 'frontier/nucleus';
import {map, Subscription} from 'rxjs';
import {MatMenuTrigger} from '@angular/material/menu';
import {ECalendar} from '../../calendar/calendar.type';
import {ToolbarStore} from './toolbar.store';
import { DatePickerComponent } from '../../calendar/easy-date-picker/date-picker/date-picker.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { CalendarComponent } from '../../calendar/calendar/calendar.component';
import { IModuleFilter, ITenant } from './tenant-menu/tenant-menu.component';
import { IMenuOption } from './user-menu/menu-option.interface';
import { EGranularity } from '../../calendar/calendar/granularity.enum';
import { IClassicCalendar } from './interfaces';
import { IDatepickerForm } from '../../calendar/easy-date-picker/date-picker/datepicker-form.interface';

@Component({
  selector: 'lib-toolbar',
  standalone: true,
  imports: [
    MatButtonToggle,
    MatButtonToggleGroup,
    MatIcon,
    MatIconButton,
    MatPrefix,
    MatRipple,
    MatSuffix,
    MatToolbar,
    MatTooltip,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    AsyncPipe,
    MatMenuTrigger,
    DatePickerComponent,
    UserMenuComponent,
    CalendarComponent
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent {
  private _userService = inject(UserService);
  toolbarService = inject(ToolbarStore);

  @ViewChild(UserMenuComponent, {static: true}) userMenu: UserMenuComponent;

  @Output() mainMenuClicked = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() themeToggle = new EventEmitter();
  @Output() applyTheme = new EventEmitter<string>();
  @Output() optionTriggered = new EventEmitter<any>()

  productName = input<string>();
  showTenantMenu = input<boolean>(true);
  logoPath = input<string>(null);
  productLogoPath = input<string>(null);
  menuOptions = input<IMenuOption[]>([]);
  versionNumber = input<string>('');
  helpUrl = input<string | null>(null);
  moduleFilters = input<IModuleFilter[]>([]);

  searchFilter = this.toolbarService.searchFilter;

  @Input() textFilterControl: FormControl = new FormControl(this.searchFilter());
  private _initialFullTextFilter: string = this.searchFilter();

  lowestGranularity = EGranularity.week;

  protected readonly EsvgFiles = EsvgFiles;
  protected readonly ECalenderType = ECalendar;

  protected themeService = inject(ThemeService);
  private _subs = new Subscription();

  tenant$ = this._userService.userGetGetTenantInfos().pipe(map(((_) => {
    const res = _ as {
      tenants: ITenant[],
      current: string
    }
    return res.tenants.find((tenant: ITenant) => {
      return tenant.guid === res.current
    }).name;
  })));


  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  onCalenderTypeChange(evt: MatButtonToggleChange) {
    this.toolbarService.state.update(state => {
      return {
        ...state,
        calendarType: evt.value
      }
    });
  }

  onDateChange(evt: IClassicCalendar) {
    this.toolbarService.state.update(state => {
      return {
        ...state,
        classicalCalendar: evt
      }
    });
  }

  onEasyDatePickerChange(evt: IDatepickerForm) {
    this.toolbarService.state.update(state => {
      return {
        ...state,
        easyDatePicker: evt
      }
    });
  }

  updateTextFilter(filterText: string) {
    this.toolbarService.state.update(state => {
      return {
        ...state,
        filter: {
          ...state.filter,
          searchstring: filterText
        }
      }
    });
  }

  onFocusFullTextFilter(value: string) {
    this._initialFullTextFilter = value;
  }

  checkForFullTextFilterChanges(value: string) {
    if (this._initialFullTextFilter === value) return;
    this.updateTextFilter(value);
    this._initialFullTextFilter = value;
  }

  onClear() {
    this.toolbarService.resetFullTextFilter$.emit()
    this.textFilterControl.patchValue('');
  }
}
